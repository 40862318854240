// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import colors from 'vuetify/lib/util/colors'

export default createVuetify({
  theme: {
    defaultTheme: 'dark',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#F43D5E", // #E53935
          secondary: colors.lightBlue.lighten4, // #FFCDD2
          background: '#F7F7FB'
        }
      },
    },
  },
})